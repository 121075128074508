import React, { memo } from "react"

import type {
  SubscriptionType,
  ChangeSubscriptionPageType,
  AllLayoutsType,
} from "@lesmills/gatsby-theme-common"
import {
  convertCentsToEuro,
  formatIntervalUnit,
  getSubscriptionType,
  CURRENCIES,
} from "@lesmills/gatsby-theme-common"

type Props = {|
  subscription: SubscriptionType,
  prismicData: ChangeSubscriptionPageType,
  layoutData?: AllLayoutsType,
  isActive?: Boolean,
  handleSelectSubscription: () => void,
|}

const Subscription = ({
  subscription,
  prismicData,
  isActive,
  layoutData,
  handleSelectSubscription,
}: Props) => {
  const { name, product_handle, currency } = subscription
  const product_price_point = subscription.product_price_point || {}
  const { price_in_cents } = product_price_point
  const unit = formatIntervalUnit(product_price_point, layoutData)
  const price = convertCentsToEuro(price_in_cents, currency)
  const subscriptionType = getSubscriptionType(product_price_point, layoutData)
  const { payment_text = {} } = prismicData

  return (
    <div
      key={product_handle}
      onClick={() => handleSelectSubscription(subscription)}
      className={`w-full py-6 md:px-20 px-3 mt-3 border border-gray-800 ${
        isActive ? "shadow shadow-lg border-1.3" : "opacity-75"
      }`}
      data-cy="available-subscription-container"
      data-testid="available-subscription-container"
    >
      <div className="flex justify-between">
        <label
          className="font-base-black text-base leading-snug text-gray-800 md:leading-7none md:text-2lg"
          data-cy="available-subscription-type"
          data-testid="available-subscription-type"
        >
          {name}
        </label>
      </div>
      <div className="flex justify-between md:mt-4 mt-3">
        <label className="font-base-light text-gray-800 md:leading-7none md:text-2lg text-3xs text-gray-800 leading-3normal">
          {subscriptionType} {payment_text.text}
        </label>
        <p
          className="font-base-black text-gray-800 md:leading-7none md:text-2lg text-3xs text-gray-800 leading-3normal"
          data-cy="available-subscription-price"
          data-testid="available-subscription-price"
        >
          {CURRENCIES[currency]}
          {price}/{unit}
        </p>
      </div>
    </div>
  )
}

export default memo(Subscription, (prevProps, nextProps) => {
  return prevProps.isActive === nextProps.isActive
})
