import React from "react"
import Subscription from "./Subscription"

type Props = {|
  subs: Array<object>,
  selectedSubscription: object,
  layoutData: object,
  handleSelectSubscription: () => void,
  prismicData: object,
|}

const Subscriptions = ({
  subs,
  selectedSubscription,
  layoutData,
  handleSelectSubscription,
  prismicData,
}: Props) => (
  <>
    {subs.map(item => {
      const { product_handle } = item

      return (
        <Subscription
          key={product_handle}
          subscription={item}
          isActive={selectedSubscription.product_handle === product_handle}
          layoutData={layoutData}
          handleSelectSubscription={handleSelectSubscription}
          prismicData={prismicData}
        />
      )
    })}
  </>
)

export default Subscriptions
